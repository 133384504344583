import React from 'react';

const Pets = () => {
  return (
    <div>
      <p>Poots?</p>
    </div>
  );
};

export default Pets;
