import { Button, Card, Form, Input, Typography, message } from 'antd';
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { auth } from '../Utils/firebase';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  getAuth,
} from 'firebase/auth';

const Login = () => {
  const authorization = getAuth();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    console.log(values);
    let email = values.email;
    let password = values.password;
    setPersistence(authorization, browserSessionPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log(user);
          navigate('/dashboard');
          message.success({
            content: 'Succesfully logged in, welcome!',
            duration: 2,
          });
        })
        .catch((error) => {
          message.error({
            content: 'Could not log you in with the given credentials.',
            duration: 2,
          });
        });
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          width: '20vw',
          height: '60vh',
          boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '6vh' }}>
          <Typography.Title level={2}>Sign In</Typography.Title>
        </div>
        <Form onFinish={onFinish}>
          <Typography.Title level={3}>Username</Typography.Title>
          <Form.Item name="email">
            <Input />
          </Form.Item>

          <Typography.Title level={3}>Password</Typography.Title>
          <Form.Item name="password">
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                padding: '8px 24px',
                height: 44,
                borderRadius: 6,
                width: '15vw',
              }}
              htmlType="submit"
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
