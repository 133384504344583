import { Progress } from 'antd';
import React from 'react';

export interface HealthbarProps {
  current: number;
  max: number;
  title: string;
  type: 'line' | 'circle';
}

const Healthbar = (props: HealthbarProps) => {
  const percent = (props.current / props.max) * 100;

  let color;
  if (percent <= 30) color = '#D2222D';
  else if (percent <= 60) color = '#FFBF00';
  else color = '#007000';

  return (
    <div
      style={{
        width: '100%',
        marginTop: -8,
      }}
    >
      <p style={{ marginBottom: -5 }}>{props.title}</p>
      <Progress width={100} percent={percent} strokeColor={color} />
    </div>
  );
};

export default Healthbar;
