import { useState, useEffect } from 'react';
import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue, get, child } from 'firebase/database';

import Home from './Pages/Home';
import Login from './Pages/Login';
import MenuPage from './Pages/MenuPage';
import AppLayout from './AppLayout';
import NotFound from './Pages/NotFound';
import Dashboard, { DashboardProps } from './Pages/Dashboard/Dashboard';
import Ship, { ShipProps } from './Pages/Dashboard/Ship';
import Jobs, { JobsProps } from './Pages/Dashboard/Jobs';
import Pets from './Pages/Dashboard/Pets';
import Notes, { NotesProps } from './Pages/Dashboard/Notes';
import Party from './Pages/Dashboard/Party';

export interface GlobalModel {
  nextSession: number;
}

export interface UserModel {
  name: string;
  coins: number;
  takenJob: TakenJob;
  notes: string;
}

export interface JobModel {
  name: string;
  description: string;
  dailyWage: number;
  hourlyChanceOnDrop: number;
}

export interface ShipModel {
  name: string;
  coins: number;
  health: {
    hull: number;
    mast: number;
    sail: number;
  };
}

export interface TakenJob {
  job: JobModel;
  takenSince: number;
}

function App() {
  const auth = getAuth();
  const db = getDatabase();

  const dbUserRef = ref(db, 'users/');

  const [user, setUser] = useState<UserModel>({
    name: 'Loading',
    coins: 0,
    takenJob: {
      job: { name: '', description: '', hourlyChanceOnDrop: 0, dailyWage: 0 },
      takenSince: -1,
    },
    notes: '',
  });
  const [jobs, setJobs] = useState<JobModel[]>([]);
  const [global, setGlobal] = useState<GlobalModel>({ nextSession: -1 });
  const [date, setDate] = useState(Date.now() / 1000);
  const [ship, setShip] = useState<ShipModel>({
    name: 'Loading',
    coins: 0,
    health: { hull: 100, mast: 100, sail: 100 },
  });
  const [userActive, setUserActive] = useState(false);
  const [reload, setReload] = useState(0);

  const logout = () => {
    setUserActive(false);
  };

  function doReload() {
    if (reload > 0) setReload(reload - 1);
    else setReload(reload + 1);
  }

  useEffect(() => {
    onValue(child(dbUserRef, `${auth.currentUser?.uid}/`), (snapshot) => {
      if (snapshot.exists()) {
        setUser(snapshot.val());
        console.log(snapshot.val());
      }
    });
    onAuthStateChanged(auth, (u) => {
      if (u) {
        get(child(dbUserRef, `${u.uid}/`)).then((snapshot) => {
          if (snapshot.val()) {
            setUser(snapshot.val());
          }
        });
      }
    });
  }, [db]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(Date.now() / 1000);
    }, 1000);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    const dbJobsRef = ref(db, 'jobs/');
    const dbGlobalRef = ref(db, 'global/');
    const dbShipRef = ref(db, 'ship/');

    onValue(dbJobsRef, (snapshot) => {
      if (snapshot.exists()) {
        let jobArray: JobModel[] = [];
        let data = snapshot.val();

        Object.keys(data).forEach((key) => {
          jobArray.push(data[key]);
        });

        setJobs(jobArray);
      }
    });
    onValue(dbGlobalRef, (snapshot) => {
      if (snapshot.exists()) {
        setGlobal(snapshot.val());
        console.log(snapshot.val());
      }
    });
    onValue(dbShipRef, (snapshot) => {
      if (snapshot.exists()) {
        setShip(snapshot.val());
        console.log(snapshot.val());
      }
    });
  }, [db]);

  let dashboardProps: DashboardProps = {
    auth,
    user,
    global,
    date,
    reload: doReload,
  };

  let jobsProps: JobsProps = {
    date,
    jobs,
    user,
    global,
    auth,
    db,
    reload: doReload,
  };

  let shipProps: ShipProps = {
    auth,
    ship,
    user,
    db,
  };

  let notesProps: NotesProps = {
    auth,
    db,
    user,
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppLayout handleSingout={logout} />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/dashboard" element={<Dashboard {...dashboardProps} />}>
          <Route path="jobs" element={<Jobs {...jobsProps} />} />
          <Route path="party" element={<Party />} />
          <Route path="pets" element={<Pets />} />
          <Route path="ship" element={<Ship {...shipProps} />} />
          <Route path="notes" element={<Notes {...notesProps} />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
