import React from 'react';

import { Typography } from 'antd';

const Home = () => {
  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Welcome!
      </Typography.Title>
    </div>
  );
};

export default Home;
