import React, { useEffect, useState } from 'react';

import { Auth, getAuth } from 'firebase/auth';
import {
  Database,
  ref,
  set,
  update,
  DatabaseReference,
} from 'firebase/database';
import JobPost from '../../Components/Dashboard/JobPost';
import { JobModel, TakenJob, UserModel, GlobalModel } from '../../App';
import { Divider, message, Statistic, Button, Modal } from 'antd';

export interface JobsProps {
  date: number;
  global: GlobalModel;
  user: UserModel;
  auth: Auth;
  jobs: JobModel[];
  db: Database;
  reload: Function;
}

const Jobs = (props: JobsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dbUserRef = ref(props.db, `users/${props.auth.currentUser?.uid}/`);

  const setJobToUser = (job: JobModel) => {
    let takenJob: TakenJob = {
      job: job,
      takenSince: Math.round(Date.now() / 1000),
    };
    console.log('Setting job..');
    update(dbUserRef, {
      takenJob: takenJob,
    })
      .then(() => {
        message.success({
          content: 'You are now succesfully employed as ' + job.name + '!',
          duration: 2,
        });
        props.reload();
      })
      .catch(() => {
        message.error({
          content:
            'You did not succesfully apply to be ' +
            job.name +
            '. Please contact the DM.',
          duration: 2,
        });
      });
  };

  useEffect(() => {}, [props.db]);

  let totalWage =
    props.user !== undefined
      ? Math.round(
          (props.user?.takenJob.job.dailyWage *
            ((props.date - props.user?.takenJob.takenSince) % (3600 * 24))) /
            36
        ) / 100
      : 0;

  let totalDropPercentage =
    props.user !== undefined
      ? (
          (1 -
            Math.pow(
              1 - props.user?.takenJob.job.hourlyChanceOnDrop / 100,
              ((props.date - props.user.takenJob.takenSince) % (3600 * 24)) /
                3600
            )) *
          100
        ).toFixed(2)
      : 0;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);

    if (!props.user) {
      message.error({
        content:
          'You did not succesfully finish working. Please contact the DM',
        duration: 2,
      });
      return;
    }

    update(dbUserRef, {
      coins: props.user?.coins + Math.round(totalWage),
      takenJob: {
        job: '',
        takenSince: -1,
      },
    })
      .then(() => {
        message.success({
          content:
            'You succesfully finished your work and earned ' +
            Math.round(totalWage) +
            ' copper coins!',
          duration: 2,
        });
      })
      .catch(() => {
        message.error({
          content:
            'You did not succesfully finish working. Please contact the DM',
          duration: 2,
        });
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 20,
        }}
      >
        {props.user?.takenJob.job.dailyWage !== undefined ? (
          <div
            style={{
              borderRadius: 10,
              backgroundColor: '#ffffff',
              boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
              padding: '4px 20px 8px',
              width: '27vw',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>
              {props.user?.takenJob.job.name}
            </h2>
            <p style={{ flexGrow: 1 }}>
              {props.user?.takenJob.job.description}
            </p>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 8,
                }}
              >
                <div>
                  <Statistic
                    title="Daily wage (Copper)"
                    value={props.user?.takenJob.job.dailyWage}
                    valueStyle={{ textAlign: 'center' }}
                  />
                </div>
                <div>
                  <Statistic
                    title="Hourly drop chance"
                    value={props.user?.takenJob.job.hourlyChanceOnDrop + '%'}
                    valueStyle={{ textAlign: 'center' }}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: 8,
              }}
            >
              <div>
                <Statistic
                  title="Total wage (Copper)"
                  value={totalWage}
                  valueStyle={{ textAlign: 'center' }}
                />
              </div>
              <div>
                <Statistic
                  title="Total drop chance"
                  value={totalDropPercentage + '%'}
                  valueStyle={{ textAlign: 'center' }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Button
                type="primary"
                style={{
                  width: '85%',
                  height: 40,
                  borderRadius: 6,
                  marginLeft: 16,
                  marginTop: 10,
                  marginBottom: 8,
                }}
                onClick={() => showModal()}
              >
                Finish work
              </Button>
            </div>
          </div>
        ) : (
          props.jobs?.map((job) => (
            <JobPost job={job} setJob={setJobToUser} key={job.name} />
          ))
        )}
      </div>
      <Modal
        title="Finishing Work"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            height: 40,
            borderRadius: 6,
            marginLeft: 16,
            marginTop: 10,
            marginBottom: 8,
          },
        }}
        cancelButtonProps={{
          style: {
            height: 40,
            borderRadius: 6,
            marginLeft: 16,
            marginTop: 10,
            marginBottom: 8,
          },
        }}
      >
        <p>Are you sure you want to finish working?</p>
        <p>
          You will receive <b>{Math.floor(totalWage)}</b> copper coins and have
          a <b>{totalDropPercentage}%</b> chance to get a random drop.
        </p>
      </Modal>
    </div>
  );
};

export default Jobs;

//
