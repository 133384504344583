import { Button, Statistic } from 'antd';
import React from 'react';
import { JobModel } from '../../App';

export interface JobPostProps {
  job: JobModel;
  setJob: Function;
}

const JobPost = (props: JobPostProps) => {
  return (
    <div
      style={{
        borderRadius: 10,
        backgroundColor: '#ffffff',
        boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
        padding: '4px 20px 8px',
        width: '17vw',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3 style={{ textAlign: 'center' }}>{props.job.name}</h3>
      <p style={{ flexGrow: 1 }}>{props.job.description}</p>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 8,
            gap: 30,
          }}
        >
          <div>
            <Statistic
              title="Daily wage (Copper)"
              value={props.job.dailyWage}
              valueStyle={{ textAlign: 'center' }}
            />
          </div>
          <div>
            <Statistic
              title="Hourly drop chance"
              value={props.job.hourlyChanceOnDrop + '%'}
              valueStyle={{ textAlign: 'center' }}
            />
          </div>
        </div>
        <Button
          type="primary"
          style={{
            width: '100%',
            height: 40,
            borderRadius: 6,
            marginTop: 10,
            marginBottom: 8,
          }}
          onClick={() => props.setJob(props.job)}
        >
          Employ
        </Button>
      </div>
    </div>
  );
};

export default JobPost;
