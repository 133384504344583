import { Button, Input, Form, message } from 'antd';
import { Auth } from 'firebase/auth';
import { DatabaseReference, Database, update, ref } from 'firebase/database';
import React, { useState } from 'react';
import { UserModel } from '../../App';

const { TextArea } = Input;

export interface NotesProps {
  auth: Auth;
  db: Database;
  user: UserModel;
}

const Notes = (props: NotesProps) => {
  const [notes, setNotes] = useState(props.user.notes);

  const dbUserRef = ref(props.db, `users/${props.auth.currentUser?.uid}/`);

  const saveNotes = () => {
    update(dbUserRef, {
      notes: notes,
    })
      .then(() => {
        message.success({
          content: 'Succesfully saved notes!',
          duration: 3,
        });
      })
      .catch(() => {
        message.error({
          content: 'Unable to update notes.',
          duration: 3,
        });
      });
  };

  console.log(props.user.notes);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          borderRadius: 10,
          backgroundColor: '#ffffff',
          boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
          padding: '12px',
          width: '50vw',
          height: '70vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          alignItems: 'end',
        }}
      >
        <TextArea
          style={{ flexGrow: 1, maxHeight: '100%' }}
          onChange={(e) => {
            setNotes(e.currentTarget.value);
          }}
          defaultValue={props.user.notes}
        />

        <Button
          style={{
            padding: '8px 24px',
            height: 44,
            borderRadius: 6,
            width: '10vw',
          }}
          type="primary"
          onClick={() => saveNotes()}
          disabled={notes === props.user.notes}
        >
          Save Notes
        </Button>
      </div>
    </div>
  );
};

export default Notes;
