// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBTsmEcSdRWMnzifsMtCK1RuumscJ-PjZ8',
  authDomain: 'website-6ea61.firebaseapp.com',
  databaseURL:
    'https://website-6ea61-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'website-6ea61',
  storageBucket: 'website-6ea61.appspot.com',
  messagingSenderId: '861876942744',
  appId: '1:861876942744:web:4c0b2383e9856eb83e1ded',
  measurementId: 'G-53G6CSCV4D',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
