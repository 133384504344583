import React from 'react';
import { Daan } from './constants';

function coinsToDenominations(coins: number) {
  // Create an empty array for the result.
  let result: number[] = [];

  // Add the hundreds first.
  result.push(Math.floor(coins / 100));

  // Now we want to push the tens.
  result.push(Math.floor((coins % 100) / 10));

  // Finally we want to push the unit digits.
  result.push(coins % 10);

  return result;
}

function isAllowedToSee(uids: string[], user?: string) {
  if (!user) return false;
  return user === Daan || uids.includes(user);
}

export { coinsToDenominations, isAllowedToSee };
