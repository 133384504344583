import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';

export interface MenuItemProps {
  round: string;
  name: string;
  description: string;
  drink: string;
  creators: string;
  desktop: boolean;
}

const MenuItem = (props: MenuItemProps) => {
  let f1 = props.desktop ? 72 : 36;
  let f2 = props.desktop ? 60 : 30;
  let f3 = props.desktop ? 36 : 20;

  let marg = props.desktop ? 90 : 50;

  return (
    <>
      <div style={{}}>
        <Typography.Title
          level={1}
          className="menu-text"
          style={{ fontSize: f2, textAlign: 'center', marginBottom: -marg / 3 }}
        >
          {props.round}
        </Typography.Title>
        <Typography.Title
          level={2}
          style={{ fontSize: f3 / 2, textAlign: 'center' }}
        >
          ({props.creators})
        </Typography.Title>
      </div>
      <div>
        <Typography.Paragraph style={{ fontSize: f3 / 2, textAlign: 'center' }}>
          {props.description}
        </Typography.Paragraph>
      </div>
      <div>
        <Typography.Title
          level={4}
          style={{ fontSize: f3 / 2, textAlign: 'center' }}
        >
          Bijbehorende drank:
        </Typography.Title>
        <Typography.Paragraph style={{ fontSize: f3 / 2, textAlign: 'center' }}>
          {props.drink}
        </Typography.Paragraph>
      </div>

      <div style={{ marginBottom: marg }}></div>
    </>
  );
};

export default MenuItem;
