import React, { useState, useEffect } from 'react';
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  updateProfile,
  signOut,
} from 'firebase/auth';
import { getDatabase, ref, onValue, set } from 'firebase/database';
import { Layout, Menu, Statistic, theme, Button, Divider, message } from 'antd';
import NotFound from '../NotFound';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { JobModel, UserModel, GlobalModel, TakenJob } from '../../App';
import Balance from '../../Components/Dashboard/Balance';
import { auth } from '../../Utils/firebase';

const { Header, Footer, Sider, Content } = Layout;

export interface DashboardProps {
  auth: Auth;
  user: UserModel;
  global: GlobalModel;
  date: number;
  reload: Function;
}

const Dashboard = (props: DashboardProps) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let navigate = useNavigate();
  let location = useLocation();

  const menuButtons = [
    {
      label: <Link to="/dashboard">Home</Link>,
      key: 'home',
    },
    {
      label: <Link to="/dashboard/jobs">Jobs</Link>,
      key: 'jobs',
    },
    {
      label: <Link to="/dashboard/party">Party</Link>,
      key: 'party',
    },
    {
      label: <Link to="/dashboard/ship">Ship</Link>,
      key: 'ship',
    },
    {
      label: 'Town',
      key: 'town',
      disabled: true,
      children: [
        {
          label: <Link to="/dashboard/alchemist">Alchemist</Link>,
          key: 'alchemist',
        },
        {
          label: <Link to="/dashboard/blacksmith">Blacksmith</Link>,
          key: 'blacksmith',
        },
        {
          label: <Link to="/dashboard/bounties">Bounties</Link>,
          key: 'bounties',
        },
        {
          label: <Link to="/dashboard/general">General</Link>,
          key: 'general',
        },
        {
          label: <Link to="/dashboard/greenhouse">Greenhouse</Link>,
          key: 'greenhouse',
        },
      ],
    },
    {
      label: (
        <Link to="/dashboard/notes">
          <b>Notes</b>
        </Link>
      ),
      key: 'notes',
    },
  ];

  const doSignout = () => {
    signOut(auth)
      .then(() => {
        message.success({
          content: 'Succesfully signed you out!',
          duration: 2,
        });
      })
      .catch(() => {
        message.error({
          content: 'Could not sign you out?!',
          duration: 5,
        });
      });
    navigate('/');
  };

  if (props.auth.currentUser == null) return <NotFound />;

  return (
    <Layout hasSider style={{ backgroundColor: '#aadbc1' }}>
      <Sider theme="light" style={{ backgroundColor: '#aadbc1' }}>
        <div
          style={{
            borderRadius: 10,
            paddingTop: 4,
            paddingBottom: 8,
            backgroundColor: colorBgContainer,
            boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
          }}
        >
          <div style={{ margin: '0px 4px' }}>
            <Balance coins={props.user.coins} title={'Balance'} />
          </div>

          <Menu
            items={menuButtons}
            mode="inline"
            theme="light"
            defaultSelectedKeys={['home']}
          ></Menu>
        </div>
      </Sider>
      <Content>
        <div style={{ paddingLeft: 16 }}>
          <Outlet />
        </div>
      </Content>
      <Sider
        style={{
          backgroundColor: '#aadbc1',
        }}
      >
        <div
          style={{
            borderRadius: 10,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: colorBgContainer,
            boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
            padding: '8px 8px',
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            Logged in as:
          </h3>
          <h3 style={{ textAlign: 'center' }}>
            {auth.currentUser?.email == undefined
              ? 'Guest'
              : auth.currentUser?.email?.charAt(0).toUpperCase().toString() +
                auth.currentUser?.email?.substring(1, 5)}
          </h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginBottom: 16,
            }}
          >
            <Button type="default" onClick={() => props.reload()}>
              Refresh
            </Button>
            <Button type="primary" onClick={() => doSignout()}>
              Sign Out
            </Button>
          </div>
        </div>
        <br />
        <div
          style={{
            borderRadius: 10,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: colorBgContainer,
            boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            Time until next session
          </h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0px 20px',
            }}
          >
            {props.global.nextSession == -1 ? (
              <div style={{ padding: '0px 20px', marginTop: -16 }}>
                <h3 style={{ textAlign: 'center', margin: '16px -8px' }}>
                  There is no session planned yet
                </h3>
              </div>
            ) : (
              <>
                <Statistic
                  title="Days"
                  value={Math.floor(
                    (props.global.nextSession - props.date) / 3600 / 24
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
                <Statistic
                  title="Hours"
                  value={Math.floor(
                    ((props.global.nextSession - props.date) % (3600 * 24)) /
                      3600
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
                <Statistic
                  title="Minutes"
                  value={Math.ceil(
                    ((props.global.nextSession - props.date) % 3600) / 60
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
              </>
            )}
          </div>
        </div>
        <br />
        <div
          style={{
            borderRadius: 10,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: colorBgContainer,
            boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            Current job
          </h3>
          {props.user?.takenJob?.takenSince === -1 ? (
            <div style={{ padding: '0px 20px' }}>
              <h3 style={{ textAlign: 'center' }}>
                You don't have an active job right now
              </h3>
            </div>
          ) : (
            <>
              <h2 style={{ textAlign: 'center' }}>
                {props.user.takenJob.job.name}
              </h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '0px 20px',
                }}
              >
                <Statistic
                  title="Days"
                  value={Math.floor(
                    (props.date - props.user.takenJob.takenSince) / 3600 / 24
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
                <Statistic
                  title="Hours"
                  value={Math.floor(
                    ((props.date - props.user.takenJob.takenSince) %
                      (3600 * 24)) /
                      3600
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
                <Statistic
                  title="Minutes"
                  value={Math.floor(
                    ((props.date - props.user.takenJob.takenSince) % 3600) / 60
                  )}
                  valueStyle={{ textAlign: 'center' }}
                />
              </div>
            </>
          )}
        </div>
      </Sider>
    </Layout>
  );
};

export default Dashboard;
