import { Typography, Row, Divider, Col } from 'antd';
import Title from 'antd/es/skeleton/Title';
import React, { useState } from 'react';
import MenuItem, { MenuItemProps } from '../Components/MenuItem';

const MenuPage = () => {
  const [desktop, setDesktop] = useState(
    window.matchMedia('(min-width: 768px)').matches
  );
  const menuData = [
    {
      name: 'Gerecht',
      description:
        'Een geabricoteerde mix van romige ricotta en verse kruiden, gewikkeld in rauwe iberico-ham, geserveerd op een bedje van knapperige rucola.',
      round: 'Amuse',
      drink: 'Prosecco',
      creators: 'Daan & Julia',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description:
        'Gerookte kip in een huisgemaakte sinaasappel-kerriedressing, smaakvol geserveerd in een borrelglaasje',
      round: 'Voorgerecht',
      drink: 'Appelcider',
      creators: 'Judith & Sebas',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description:
        'Een geroosterde paprikasoep met verse biologisch geteelde groenten, gedecoreerd met een toefje crème fraiche',
      round: 'Soep',
      drink: 'Bloody Mary',
      creators: 'Friso & Joran',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description:
        'Een smakelijke ratatouille van aubergine, courgette en tomaat, gedrapeerd met een romige en kruidige huisgemaakte tomatensaus',
      round: 'Tussengerecht',
      drink: 'Droge rosé',
      creators: 'Sebas & Daan',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description:
        'Een frisse sgroppino met prosecco, wodka en ambachtelijke citroenroomijs',
      round: 'Spoom',
      drink: 'Meer sgroppino',
      creators: 'Merel & Judith',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description:
        'Een stoofpot met sukadelappen en winterpeen, feestelijk aangekleed met gedroogd fruit en verse kruiden',
      round: 'Hoofdgerecht',
      drink: 'Hertog-Jan bock-bier',
      creators: 'Julia & Friso',
      desktop: desktop,
    },
    {
      name: 'Gerecht',
      description: 'Huisgemaakte limoncello-pannacotta met cantuccini',
      round: 'Dessert',
      drink: 'Limoncello',
      creators: 'Joran & Merel',
      desktop: desktop,
      noLine: true,
    },
  ];

  let titleFont = desktop ? 96 : 48;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          backgroundColor: '#fff',
          width: desktop ? '50vw' : '85vw',
          padding: '2vh 5vw',
        }}
      >
        <Row justify={'center'}>
          <Col>
            <Typography.Title
              level={1}
              className="menu-text"
              style={{ fontSize: titleFont, textAlign: 'center' }}
            >
              Menu
            </Typography.Title>
            <Typography.Paragraph
              className="menu-text"
              style={{
                fontSize: titleFont / 2.5,
                textAlign: 'center',
                marginTop: desktop ? -85 : -30,
              }}
            >
              05-02-2023
            </Typography.Paragraph>
          </Col>
        </Row>
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {menuData.map((dish) => (
            <div key={dish.round}>
              <MenuItem
                name={dish.name}
                description={dish.description}
                round={dish.round}
                drink={dish.drink}
                creators={dish.creators}
                desktop={dish.desktop}
              />
              {!dish.noLine ? <hr style={{ width: '50%' }} /> : <></>}
            </div>
          ))}
        </div>
        <Divider />
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          Na afloop van het diner is er uiteraard gelegenheid om de
          festiviteiten te verlengen met verscheidene alcoholische
          versnaperingen naar keuze, begeleid door een assortiment aan festieve
          hapjes
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default MenuPage;
