import React, { useEffect, useState } from 'react';
import './App.css';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';

import { Layout, Menu, Button, theme, message } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

const menuItems = [
  {
    label: <Link to="/menu">Menu</Link>,
    key: 'menu',
  },
];

export interface AppLayoutProps {
  handleSingout: Function;
}

function AppLayout(props: AppLayoutProps) {
  const [desktop, setDesktop] = useState(
    window.matchMedia('(min-width: 768px)').matches
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const auth = getAuth();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
  }, []);

  const logout = () => {
    signOut(auth)
      .then(() => {
        props.handleSingout();
        navigate('/');
        message.success({
          content: 'Succesfully logged out!',
          duration: 2,
        });
      })
      .catch(() => {
        message.error({
          content: 'Could not log you out, please contact an admin!',
          duration: 2,
        });
      });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!loggedIn && (
        <Header
          style={{
            background: colorBgContainer,
            width: '100%',
            boxShadow: '0px 2px 3px 0px rgba(10,10,10,0.64)',
          }}
        >
          <Layout
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: colorBgContainer,
            }}
          >
            <div
              style={{ width: '25vw', display: 'flex', alignItems: 'center' }}
            ></div>
            <Menu
              theme="light"
              items={menuItems}
              mode="horizontal"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '70%',
                marginTop: 0,
              }}
            ></Menu>
            <div
              style={{ width: '25vw', display: 'flex', justifyContent: 'end' }}
            >
              {loggedIn ? (
                <>
                  <Button
                    type="default"
                    style={{
                      width: 95,
                      height: 40,
                      borderRadius: 6,
                    }}
                    onClick={() => navigate('/dashboard')}
                    disabled={location.pathname.startsWith('/dashboard')}
                  >
                    Dashboard
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      width: 95,
                      height: 40,
                      borderRadius: 6,
                      marginLeft: 12,
                    }}
                    onClick={() => logout()}
                  >
                    Sign Out
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  style={{ width: 90, height: 36, borderRadius: 6 }}
                  onClick={() => navigate('/login')}
                >
                  Sign In
                </Button>
              )}
            </div>
          </Layout>
        </Header>
      )}

      <Layout style={{ backgroundColor: '#aadbc1' }}>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            height: '100%',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      {!loggedIn && (
        <Footer style={{ background: colorBgContainer, textAlign: 'center' }}>
          Site by Daan van Dongen © {new Date().getFullYear()}
        </Footer>
      )}
    </Layout>
  );
}

export default AppLayout;
