import React from 'react';
import { Statistic } from 'antd';
import { coinsToDenominations } from '../../Utils/Utils';

export interface BalanceProps {
  coins: number;
  title: string;
}

const Balance = (props: BalanceProps) => {
  let denoms = coinsToDenominations(props.coins);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: '#b1d2ff',
          height: '140px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h2 style={{ marginLeft: 20 }}>{props.title}</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0px 20px',
          }}
        >
          <Statistic
            title="Gold"
            value={denoms[0]}
            valueStyle={{ textAlign: 'center' }}
          />
          <Statistic
            title="Silver"
            value={denoms[1]}
            valueStyle={{ textAlign: 'center' }}
          />
          <Statistic
            title="Copper"
            value={denoms[2]}
            valueStyle={{ textAlign: 'center' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Balance;
