import userEvent from '@testing-library/user-event';
import { Button, Form, Input, message, Modal, Statistic } from 'antd';
import { Auth } from 'firebase/auth';
import { Database, DatabaseReference, update, ref } from 'firebase/database';
import React, { useState } from 'react';
import { ShipModel, UserModel } from '../../App';
import Balance from '../../Components/Dashboard/Balance';
import Healthbar from '../../Components/Dashboard/Healthbar';
import { Joran, Julia } from '../../Utils/constants';
import { auth } from '../../Utils/firebase';
import { isAllowedToSee } from '../../Utils/Utils';

export interface ShipProps {
  auth: Auth;
  ship: ShipModel;
  user: UserModel;
  db: Database;
}

type ModalType = 'deposit' | 'withdraw';
type Money = {
  gold: number;
  silver: number;
  copper: number;
};

const Ship = (props: ShipProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>('deposit');

  const dbUserRef = ref(props.db, 'users/' + `${auth.currentUser?.uid}/`);
  const dbShipRef = ref(props.db, 'ship/');

  const openModal = (type: ModalType) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: Money) => {
    values.gold = values.gold == undefined ? 0 : values.gold;
    values.silver = values.silver == undefined ? 0 : values.silver;
    values.copper = values.copper == undefined ? 0 : values.copper;

    console.log(values);

    let coins: number =
      values.gold * 100 + values.silver * 10 + values.copper * 1;

    if (coins <= 0) {
      message.error({
        content: "You can't make a negative or zero-sum transaction.",
        duration: 4,
      });
      closeModal();
      return;
    }

    if (modalType === 'deposit') {
      if (props.user.coins < coins) {
        message.error({
          content: 'You do not have sufficient coins for this deposit.',
          duration: 4,
        });
        closeModal();
        return;
      }

      update(dbUserRef, {
        coins: props.user.coins - coins,
      }).then(() => {
        update(dbShipRef, {
          coins: props.ship.coins + coins,
        }).then(() => closeModal());
      });
    } else {
      if (props.ship.coins < coins) {
        message.error({
          content:
            "The ship's coffers do not have sufficient coins for this withdraw.",
          duration: 4,
        });
        closeModal();
        return;
      }
      console.log(props.user.coins);
      console.log(typeof props.user.coins);
      console.log(coins);
      console.log(typeof coins);

      let newUserBalance: number = props.user.coins + coins;
      let newShipBalance: number = props.ship.coins - coins;
      console.log(newUserBalance);
      console.log(newShipBalance);
      update(dbUserRef, {
        coins: newUserBalance,
      }).then(() => {
        update(dbShipRef, {
          coins: newShipBalance,
        }).then(() => closeModal());
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          borderRadius: 10,
          backgroundColor: '#ffffff',
          boxShadow: '1px 2px 3px 0px rgba(50,50,80,0.64)',
          padding: '4px 20px 8px',
          width: '53vw',
          height: '55vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            backgroundColor: '#eee',
            margin: '16px 0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ overflow: 'hidden', width: '80%' }} src="/boat.png" />
        </div>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '16px 0px 16px 16px',
            marginLeft: 8,
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h2 style={{ textAlign: 'center', marginTop: 0 }}>
                {props.ship.name}
              </h2>
              <Healthbar
                max={100}
                current={props.ship.health.hull}
                title="Hull Health"
                type="line"
              />
              <Healthbar
                max={100}
                current={props.ship.health.mast}
                title="Mast Health"
                type="line"
              />
              <Healthbar
                max={100}
                current={props.ship.health.sail}
                title="Sail Health"
                type="line"
              />
            </div>
            <div>
              <Balance coins={props.ship.coins} title="Ship Balance" />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 16,
                  width: '100%',
                  marginTop: 16,
                }}
              >
                <Button
                  style={{ flexGrow: 1 }}
                  onClick={() => openModal('deposit')}
                >
                  Deposit
                </Button>
                {isAllowedToSee(
                  [Joran, Julia],
                  props.auth.currentUser?.uid
                ) && (
                  <Button
                    style={{ flexGrow: 1 }}
                    onClick={() => openModal('withdraw')}
                  >
                    Withdraw
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        onCancel={() => closeModal()}
        title={modalType == 'deposit' ? 'Deposit' : 'Withdraw'}
        footer={<></>}
      >
        <Form onFinish={onFinish}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '40px',
              padding: 16,
            }}
          >
            <Form.Item label="Gold" name="gold">
              <Input type="number" defaultValue={0} />
            </Form.Item>
            <Form.Item label="Silver" name="silver">
              <Input type="number" defaultValue={0} />
            </Form.Item>
            <Form.Item label="Copper" name="copper">
              <Input type="number" defaultValue={0} />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              htmlType="submit"
              style={{
                padding: '8px 24px',
                height: 44,
                borderRadius: 6,
                width: '15vw',
              }}
            >
              Ok
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Ship;
