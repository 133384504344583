import { Typography } from 'antd';
import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        minWidth: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '5vh',
      }}
    >
      <Typography.Title level={1} style={{ fontSize: 96, marginBottom: -10 }}>
        404
      </Typography.Title>
      <Typography.Title level={2}>
        Oops, this page can't be found!
      </Typography.Title>
      <Typography.Paragraph>
        Expecting to see something here? Perhaps it is still work in progress,
        so stay tuned!
      </Typography.Paragraph>
    </div>
  );
};

export default NotFound;
